import React from 'react';

function Donate(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path
        fill={props.color}
        d="M225.6 232.3l50.1 14.3c3.6 1 6.1 4.4 6.1 8.1 0 4.6-3.8 8.4-8.4 8.4h-32.8c-3.6 0-7.1-.8-10.3-2.2-4.8-2.2-10.4-1.7-14.1 2l-17.5 17.5c-5.3 5.3-4.7 14.3 1.5 18.4 9.5 6.3 20.4 10.1 31.8 11.5V328c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16v-17.6c30.3-3.6 53.4-31 49.3-63-2.9-23-20.7-41.3-42.9-47.7l-50.1-14.3c-3.6-1-6.1-4.4-6.1-8.1 0-4.6 3.8-8.4 8.4-8.4h32.8c3.6 0 7.1.8 10.3 2.2 4.8 2.2 10.4 1.7 14.1-2l17.5-17.5c5.3-5.3 4.7-14.3-1.5-18.4-9.5-6.3-20.4-10.1-31.8-11.5V104c0-8.8-7.2-16-16-16h-16c-8.8 0-16 7.2-16 16v17.6c-30.3 3.6-53.4 31-49.3 63 2.9 23 20.6 41.3 42.9 47.7zM480 320h-34.7c17-30.9 26.7-66.3 26.7-104C472 96.7 375.3 0 256 0S40 96.7 40 216c0 37.7 9.7 73.1 26.7 104H32c-17.7 0-32 17.2-32 38.4v115.2C0 494.8 14.3 512 32 512h448c17.7 0 32-17.2 32-38.4V358.4c0-21.2-14.3-38.4-32-38.4zM256 48c92.6 0 168 75.4 168 168s-75.4 168-168 168S88 308.6 88 216 163.4 48 256 48zm208 416H48v-96h54.6c12.2 12.3 25.9 22.9 40.7 32H104c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h304c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8h-39.3c14.8-9.1 28.5-19.7 40.7-32H464v96z"
      />
    </svg>
  );
}

export default Donate;
